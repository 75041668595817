/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import { Helmet } from "react-helmet";
import scrollTo from "gatsby-plugin-smoothscroll";
import { StaticImage } from "gatsby-plugin-image";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Heartintune from "./content/Heartintune";
import StoryOfInternational from "./content/StoryOfInternational";
import EBookBanner from "./content/EBookBanner";

const Ebooks = () => {
  const activities = [
    { span: "Activity 1:", name: "Inspiring Quotes" },
    { span: "Activity 2:", name: "Origami: Peace Dove" },
    { span: "Activity 3:", name: "Heartful of Peace" },
    { span: "Activity 4:", name: "Chain of Peace" },
    { span: "Activity 5:", name: "Brainstorm For Peace" },
    { span: "Activity 6:", name: "Heart-In-Tune" },
    { span: "Activity 7:", name: "Read & Share" },
    { span: "Activity 8:", name: "Guided Experiences" },
  ];
  return (
    <section
      sx={{
        backgroundColor: "#ffff",
        color: "#4A4A49",
        fontFamily:
          "gotham, system-ui, -apple-system, BlinkMacSystemFont, sans-serif",
      }}
    >
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title="International Peace Day Ebooks"
      />
      <Header onMenuClick={(e) => scrollTo(`#${e}`)} />
      <EBookBanner />
      <section
        sx={{
          ml: ["12px", "20px", "40px", "60px"],
          mb: "30px",
        }}
      >
        <Flex sx={{ flexDirection: ["column", null, "row"] }}>
          <Box>
            <h3 sx={{ fontSize: "28px", color: "#0082BE" }}>
              About Heartful Schools
            </h3>
            <p>
              Heartfulness Program for Schools is designated by CASEL as a
              recommended program for social and emotional learning. This
              program is designed to develop core competencies for the whole
              school community.
            </p>
            <p>
              <a
                sx={{
                  color: "#0082BE",
                  cursor: "pointer",
                  textDecoration: "underline",
                  mt: 2,
                }}
                rel="noreferrer"
                href="https://www.heartfulnessinstitute.org/education"
                target="_blank"
              >
                Read More
              </a>
            </p>
          </Box>
          <Box sx={{ alignSelf: "flex-end" }}>
            <StaticImage
              src="./assets/img/Picture2.png"
              sx={{
                height: ["auto", "auto"],
                width: "100%",
              }}
              placeholder="blurred"
              alt="Logo"
            />
          </Box>
        </Flex>
      </section>
      <section
        sx={{
          ml: ["12px", "20px", "40px", "60px"],
          mb: "30px",
          mr: ["12px", 0],
        }}
      >
        <Flex
          sx={{
            justifyContent: "space-between",
            flexDirection: ["column", null, "row"],
          }}
        >
          <Box
            sx={{
              position: "relative",
              flex: 1,
              justifySelf: "flex-start",
              ml: [0, "-30px"],
            }}
          >
            <StaticImage
              src="./assets/img/Img_2.png"
              sx={{
                height: ["auto", "auto", null, "500px"],
                width: "100%",
              }}
              placeholder="blurred"
              alt="Logo"
            />
            <Box
              sx={{
                position: "absolute",
                bg: "#76AA3A",
                maxWidth: ["calc(100% - 50px)", "calc(100% - 90px)"],
                // width: "100%",
                // m: "60 40px",
                ml: ["25px", "45px"],
                color: "#fff",
                p: "4px 8px",
                bottom: ["40px", "70px", "60px", "30px"],
                left: 0,
                right: 0,
              }}
            >
              <h3 sx={{ m: ["3px 0", "4px 0"], fontSize: ["18px", "21px"] }}>
                How to use this book?
              </h3>
              <p sx={{ fontSize: ["16px", "18px"], m: 0 }}>
                With a lot of love
              </p>
              <p sx={{ fontSize: ["12px", "14px"], m: 0, mt: ["3px", "6px"] }}>
                Have a discussion, do a craft, try a breathing exercise or sing
                a song. What ever you do, do it with love and share the peace.
              </p>
            </Box>
          </Box>
          <Box
            sx={{ flex: 1, maxWidth: ["100%", null, "580px"], flexShrink: 0 }}
          >
            <h2 sx={{ fontSize: "27px", m: "25px 0" }}>
              For you and your students
            </h2>
            {activities.map((item) => (
              <p
                key={item.name}
                sx={{ fontSize: "18px !important", m: "18px 0" }}
              >
                <span sx={{ fontWeight: 500 }}>{item.span}</span> {item.name}
              </p>
            ))}
          </Box>
        </Flex>
      </section>
      <StoryOfInternational />
      <Box>
        <Heartintune />
      </Box>
      <Flex sx={{ flexDirection: "column", my: 3, textAlign: "center" }}>
        <StaticImage
          src="./assets/img/Picture3.png"
          sx={{
            height: ["auto", "auto", "fit-content"],
            maxWidth: ["120px", "150px"],
            m: "20px auto 10px",
          }}
          placeholder="blurred"
          alt="Logo"
        />
        <p sx={{ fontSize: ["18px", "26px", null, "38px"], my: 0 }}>
          Thank you for <b>Considering this book</b>
        </p>
        <p sx={{ m: "7px 0", fontSize: ["12px", null, null, "14px"] }}>
          If we can help in anyway, please write to us:
        </p>
        <Box sx={{ textAlign: "center", mb: "30px", mt: [0, "20px"] }}>
          <a
            sx={{
              textDecoration: "none",
              color: "#76AA3A",
              p: ["10px 30px"],
              fontWeight: 500,
              fontSize: ["14px", "17px", null, "20px"],
              boxShadow: "2px 2px 6px #00000029",
            }}
            href="mailto:edu@heartfulnessinstitute.org"
          >
            edu@heartfulnessinstitute.org
          </a>
        </Box>
      </Flex>
      <Footer />
    </section>
  );
};

export default Ebooks;
