/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import { jsx, Box, Flex } from "theme-ui";
import Slider from "react-slick";
import { inputStyles } from "../../../utils/fabricStyles";
import img1 from "../assets/img/bookq1.png";
import img2 from "../assets/img/bookq2.png";
import useEbookForm from "../utils/useEbookForm";
import config from "../assets/config";

require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");

const EBookBanner = () => {
  const initialValues = {
    ref: config.ref,
    event: config.event,
    pnr: "",
    name: "",
    email: "",
    mobile: config.mobile,
    city_id: config.city_id,
    reg_json: config.reg_json,
    communication_preference: config.communication_preference,
  };

  const { handleSubmit, inputs, errors, setInputData, instantValidation } =
    useEbookForm(initialValues);

  const settings = {
    lazyLoad: true,
    // centerMode: true,
    adaptiveHeight: true,
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
  };

  return (
    <section
      sx={{
        ml: ["12px", "20px", "40px", "60px"],
        mb: "30px",
        mr: ["12px", 0],
      }}
    >
      <Flex
        sx={{ flexDirection: ["column", null, null, "row"], gap: ["20px", 0] }}
      >
        <Box>
          <StaticImage
            src="../assets/img/Picture3.png"
            sx={{
              height: ["auto", "auto", "fit-content"],
              // maxWidth: ["120px", "150px"],
              width: "100px",
              m: "20px auto 10px",
            }}
            placeholder="blurred"
            alt="Logo"
          />
          <h1 sx={{ m: "0 0 20px", fontSize: "36px !important" }}>
            A Day of <b sx={{ color: "#76AA3A" }}>Peace</b>
          </h1>
          <p
            sx={{
              fontSize: "18px !important",
              fontWeight: 500,
              width: "300px",
            }}
          >
            Free E-Book for celebrating Peace Day in your school
          </p>
          <p sx={{ fontSize: "16px", width: "340px" }}>
            Activities for celebrating International Peace Day in your school
          </p>
          <p sx={{ fontSize: "16px" }}>
            Quotes, stories, crafts and more for everyone…
          </p>
          <Flex
            sx={{
              flexDirection: ["column", null, "row"],
              gap: ["10px", "10px", 0],
              mr: [2, 5, 0],
            }}
          >
            <input
              sx={{
                border: "1px solid #fff",
                fontSize: "14px",
                boxShadow: "-2px 2px 6px #0000001f",
                p: "8px 12px",
                outline: "none",
              }}
              type="text"
              name="name"
              placeholder="Name"
              value={inputs.name}
              onBlur={() => instantValidation("name")}
              onChange={(e) => setInputData(e)}
              style={errors.name ? inputStyles.inputError : null}
            />
            <input
              sx={{
                border: "1px solid #fff",
                fontSize: "14px",
                boxShadow: "-2px 2px 6px #0000001f",
                p: "8px 12px",
                outline: "none",
              }}
              placeholder="Email ID"
              type="email"
              name="email"
              value={inputs.email}
              onBlur={() => instantValidation("email")}
              onChange={(e) => setInputData(e)}
              style={errors.email ? inputStyles.inputError : null}
            />
            <input
              sx={{
                border: "none",
                fontSize: "14px",
                boxShadow: "-2px 2px 6px #0000001f",
                p: "8px 20px",
                bg: "#76AA3A",
                color: "#fff",
                outline: "none",
                cursor: "pointer",
              }}
              type="submit"
              value="Download E-Book"
              onClick={(e) => handleSubmit(e)}
              name="submit"
            />
          </Flex>
        </Box>
        <Box
          sx={{
            flex: 1,
            maxWidth: ["350px", null, "400px"],
            margin: ["10px auto", "10px auto", "20px auto", "0 auto"],
            position: "relative",
          }}
          variant="EBooksBannerCarosel"
        >
          <Slider {...settings}>
            <Box>
              <img
                aria-hidden="true"
                // onClick={() => loadImage(item)}
                // className="img-fluid"
                sx={{
                  // height: ["auto", "auto", "fit-content"],
                  height: "440px",
                  width: "343px",
                  // m: "20px auto 10px",
                }}
                src={img1}
                alt="logo"
                placeholder="blurred"
              />
            </Box>
            <Box>
              <img
                aria-hidden="true"
                // className="img-fluid"
                sx={{
                  height: "440px",
                  // height: ["auto", "auto", "fit-content"],
                  width: "343px",
                  // m: "20px auto 10px",
                }}
                src={img2}
                alt="logo"
                placeholder="blurred"
              />
            </Box>
          </Slider>
        </Box>
      </Flex>
    </section>
  );
};

export default EBookBanner;
