/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import { jsx, Box, Flex } from "theme-ui";

function StoryOfInternational() {
  return (
    <section
      sx={{
        ml: ["12px", "20px", "40px", "60px"],
        mb: "30px",
        mr: ["12px", 0],
      }}
    >
      <Flex
        sx={{
          gap: [0, 0, 0, "30px"],
          justifyContent: "space-between",
          flexDirection: ["column", null, null, "row"],
        }}
      >
        <Box sx={{ flex: 5, fontSize: "16px" }}>
          <h3 sx={{ color: "#0082BE", fontSize: "28px" }}>
            The Story of International Day of Peace
          </h3>
          <p>
            The making of the International Day of Peace has an interesting
            story. We have Jeremy Gilley to thank for it. He is from England and
            fought hard to make the Day of Peace fall on September 21st every
            year.
          </p>
          <p>
            {" "}
            Before 2002, the United Nations sponsored a day in honor of peace,
            but it never requested that people stopped fighting, and it
            didn&apos;t have a specific date.
          </p>
          <p>
            On September 7th, 2001, after many letters and meetings with
            different world leaders on Gilley&apos;s part, the United Nations
            agreed to create the International Day of Peace on September 21st.
          </p>
          <p>
            It was a day that asked the people of all nations to agree to
            ceasefire and nonviolence.
          </p>
        </Box>
        <Box
          sx={{
            alignSelf: ["normal", null, null, "flex-end"],
            flex: 4,
            mt: ["100px", null, null, 0],
          }}
        >
          <Box
            sx={{ bg: "#F4F4F4", height: "280px", p: "20px", width: "100%" }}
          >
            <Box sx={{ maxWidth: ["120px", "150px"], mx: "auto", mt: "-90px" }}>
              <StaticImage
                src="../assets/img/jeremy-gilley-vertical.png"
                sx={{
                  height: ["auto", "auto", "fit-content"],
                  width: "100%",
                }}
                placeholder="blurred"
                alt="Logo"
              />
            </Box>
            <h3 sx={{ textAlign: "center", m: "10px 0 0", fontSize: "18px" }}>
              Jeremy Gilley
            </h3>
            <h6 sx={{ fontSize: "18px", margin: "10px 0 0" }}>Did you Know?</h6>
            <p sx={{ m: "8px 0", fontSize: "14px" }}>
              Jeremy Gilley Is an English actor, filmmaker and Founder of Peace
              One Day.
            </p>
            <p sx={{ m: "8px 0", fontSize: "14px" }}>
              Author of children’s book - The Making of World Peace One Day
            </p>
          </Box>
        </Box>
      </Flex>
    </section>
  );
}

export default StoryOfInternational;
