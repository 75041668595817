import { useState } from "react";
import { validateEmail } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import getMandatoryEnv from "../../../utils/getMandatoryEnv";
import generatePNR from "../../../utils/generatePNR";
import useAlert from "../../../components/Alert/useAlert";
import { eventId } from "../assets/config";

const { mySrcmConfig } = getMandatoryEnv(["mySrcmConfig"]);
const { eventsClient } = mySrcmConfig;

const CryptoJS = require("crypto-js");

export default function useEbookForm(initialValues) {
  const { showAlert } = useAlert();
  const [inputs, setInputs] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [updating, setUpdating] = useState(false);

  const validate = () => {
    const errorData = {};
    // Email
    if (!inputs.email) {
      errorData.email = "Email is required";
    } else if (!validateEmail(inputs.email)) {
      errorData.email = "Invalid email address";
    }

    // Name
    if (!inputs.name) {
      errorData.name = "Name is required";
    }

    setErrors(errorData);
    return errorData;
  };

  const instantValidation = (type = "") => {
    const errorData = {};
    if (type === "email") {
      if (!inputs.email) {
        errorData.email = "Email is required";
      } else {
        delete errorData.email;
      }
      if (inputs.email && !validateEmail(inputs.email)) {
        delete errorData.email;
      }
    }
    if (type === "name") {
      if (!inputs.name) {
        errorData.name = "Name is required";
      } else {
        delete errorData.name;
      }
    }

    setErrors(errorData);
    return errorData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    const afterUpdate = () => {
      setInputs(initialValues);
      setUpdating(false);
      window.open(
        "https://cdn-prod.heartfulness.org/hfn/ipd/2021/Peace-ebook-International-School-Sept-2021.pdf"
      );
    };
    if (noErrors) {
      setUpdating(true);
      inputs.pnr = generatePNR(inputs.name);
      inputs.has_registered = true;
      inputs.event = eventId.ebooks;
      const payload = JSON.stringify(inputs);
      const clientId = eventsClient;
      const hashText = CryptoJS.HmacSHA256(payload, clientId);
      fetch(`${mySrcmConfig.profileServer}/api/v2/event-registrations/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "hmac-token": `${hashText}`,
          "x-client-id": `${clientId}`,
        },
        body: payload,
      })
        .then((response) => response.json())
        .then((registrationResponse) => {
          if ("id" in registrationResponse) {
            const alert = {
              title: "Success",
              message: `You have been successfully registered.`,
              confirm_text: "Okay",
              is_blocking: true,
              has_registered: true,
            };
            showAlert(alert);
            return afterUpdate();
          }
          const alert = {
            title: "Error",
            message:
              "Error:  Registration failed. Something went wrong. Please try again later.",
            confirm_text: "Okay",
            is_blocking: true,
          };
          showAlert(alert);
          return afterUpdate();
        });
    }
  };

  const setInputData = (event) => {
    setInputs((inputD) => ({
      ...inputD,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmit,
    inputs,
    errors,
    updating,
    setInputData,
    validate,
    instantValidation,
  };
}
